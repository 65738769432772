import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Input,
  Select,
  Card,
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Tooltip,
  message,
  Spin,
  Pagination,
} from "antd";
import { API } from "../../config/config";
import { Seller, SellerStatus, ISchedule } from "../../types/seller.type";
import { categories } from "../../constants/categories.constant";
import {
  UserOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  SyncOutlined,
  MailOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  TagOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const { Option } = Select;
const { Text, Title } = Typography;

export default function Sellers() {
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [searchName, setSearchName] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [category, setCategory] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState<SellerStatus | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalSellers, setTotalSellers] = useState<number>(0);

  const fetchSellers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API}/admin/sellers`, {
        params: {
          name: searchName,
          city: searchCity,
          category,
          status,
          page: currentPage,
          limit: pageSize,
        },
      });
      setSellers(response.data.sellers);
      setTotalSellers(response.data.total);
    } catch (error) {
      console.error("Error fetching sellers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSellers();
  }, [searchName, searchCity, category, status, currentPage, pageSize]);

  const toggleSellerStatus = async (
    sellerId: string,
    currentStatus: SellerStatus
  ) => {
    try {
      const newStatus =
        currentStatus === SellerStatus.ACTIVE
          ? SellerStatus.INACTIVE
          : SellerStatus.ACTIVE;

      await axios.post(`${API}/admin/seller/${sellerId}/status`, {
        status: newStatus,
      });

      message.success(
        `Статус продавца обновлен на ${
          newStatus === SellerStatus.ACTIVE ? "Активен" : "Неактивен"
        }`
      );
      fetchSellers();
    } catch (error) {
      console.error("Error updating seller status:", error);
      message.error("Не удалось обновить статус продавца");
    }
  };

  const renderSchedule = (schedule: ISchedule | undefined) => {
    if (!schedule || schedule.length === 0)
      return <Text type="secondary">График не указан</Text>;

    return (
      <>
        {schedule.map((item, index) => (
          <div key={index}>
            <Text>
              <strong>{item.dayOfWeek}:</strong>{" "}
              {item.is24Hours
                ? "Круглосуточно"
                : item.isDayOff
                ? "Выходной день"
                : `${item.openTime} - ${item.closeTime}`}
            </Text>
          </div>
        ))}
      </>
    );
  };
  const handleSearchName = (value: string) => {
    setSearchName(value);
    fetchSellers();
  };

  const handleSearchCity = (value: string) => {
    setSearchCity(value);
    fetchSellers();
  };
  return (
    <div style={{ padding: "24px" }}>
      <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
        Управление Продавцами
      </Title>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={6}>
          <Search
            placeholder="Поиск по имени"
            onSearch={handleSearchName}
            allowClear
            onClear={() => handleSearchName("")}
          />
        </Col>
        <Col span={6}>
          <Tooltip title="Введите город">
            <Search
              placeholder="Поиск по городу"
              onSearch={handleSearchCity}
              allowClear
              onClear={() => handleSearchCity("")}
            />
          </Tooltip>
        </Col>
        <Col span={6}>
          <Select
            placeholder="Категория"
            value={category}
            onChange={(value) => setCategory(value || undefined)}
            style={{ width: "100%" }}
            allowClear
          >
            {categories.map((cat) => (
              <Option key={cat.id} value={cat.id}>
                {cat.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            placeholder="Статус"
            value={status}
            onChange={setStatus}
            style={{ width: "100%" }}
            allowClear
          >
            <Option value={SellerStatus.ACTIVE}>
              <CheckCircleOutlined /> Активен
            </Option>
            <Option value={SellerStatus.INACTIVE}>
              <CloseCircleOutlined /> Неактивен
            </Option>
          </Select>
        </Col>
      </Row>
      <Divider />

      {loading ? (
        <Spin size="large" style={{ display: "block", margin: "auto" }} />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            {sellers.length === 0 ? (
              <Col span={24} style={{ textAlign: "center" }}>
                <Text type="secondary">Продавцы не найдены</Text>
              </Col>
            ) : (
              sellers.map((seller) => (
                <Col key={seller._id} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    hoverable
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <UserOutlined style={{ marginRight: 8 }} />{" "}
                        {seller.name}
                      </div>
                    }
                    cover={
                      <img
                        alt="Фото"
                        src={`${API}/${seller.photoUri}`}
                        style={{
                          objectFit: "cover",
                          height: "200px",
                        }}
                      />
                    }
                    bordered
                  >
                    <p>
                      <PhoneOutlined /> <strong>Телефон:</strong> {seller.phone}
                    </p>
                    <p>
                      <SyncOutlined /> <strong>Статус:</strong> {seller.status}
                    </p>
                    <p>
                      <TagOutlined /> <strong>Категория:</strong>{" "}
                      {seller.category
                        ? categories.find(
                            (cat) => cat.id.toString() === seller.category
                          )?.name || "Не указана"
                        : "Не указана"}
                    </p>
                    <p>
                      <MailOutlined /> <strong>Email:</strong>{" "}
                      {seller.email ? seller.email : "Не указан"}
                    </p>
                    <p>
                      <EnvironmentOutlined /> <strong>Адрес:</strong>{" "}
                      {seller.address.city} , {seller.address.street} , дом{" "}
                      {seller.address.house} , вход {seller.address.entrance} кв{" "}
                      {seller.address.apartment} , {seller.address.floor} этаж
                    </p>
                    <div style={{ marginTop: "8px" }}>
                      <CalendarOutlined style={{ marginRight: 8 }} />
                      {renderSchedule(seller.schedule)}
                    </div>

                    <Button
                      type={
                        seller.status === SellerStatus.ACTIVE
                          ? "default"
                          : "primary"
                      }
                      icon={<PoweroffOutlined />}
                      onClick={() =>
                        toggleSellerStatus(seller._id, seller.status)
                      }
                      style={{ marginTop: "16px" }}
                    >
                      {seller.status === SellerStatus.ACTIVE
                        ? "Деактивировать"
                        : "Активировать"}
                    </Button>
                  </Card>
                </Col>
              ))
            )}
          </Row>

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalSellers}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            }}
            style={{ marginTop: "20px", textAlign: "center" }}
            showSizeChanger
            pageSizeOptions={[5, 10, 20, 50]}
          />
        </>
      )}
    </div>
  );
}
