import {
  FiBriefcase,
  FiHome,
  FiShoppingBag,
  FiUsers,
  FiTruck,
  FiDollarSign,
  FiFileText,
} from "react-icons/fi";

export const items = [
  { key: "/", icon: <FiHome />, label: "Главная" },
  { key: "/orders", icon: <FiShoppingBag />, label: "Заказы" },
  { key: "/sellers", icon: <FiBriefcase />, label: "Селлеры" },
  { key: "/clients", icon: <FiUsers />, label: "Клиенты" },
  { key: "/couriers", icon: <FiTruck />, label: "Курьеры" },
  { key: "/pricings", icon: <FiDollarSign />, label: "Цены" },
  { key: "/applications", icon: <FiFileText />, label: "Заявки" },
];
