import React from "react";
import { Card, Statistic, StatisticProps } from "antd";
import CountUp from "react-countup";

interface StatCardProps {
  title: string;
  value: number;
  suffix?: string;
  precision?: number;
  icon?: React.ReactNode;
  className?: string;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  suffix,
  precision,
  icon,
  className,
}) => {
  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp
      end={value as number}
      duration={2}
      separator=","
      decimals={precision}
    />
  );

  return (
    <Card
      bordered={false}
      className={`stat-card ${className || ""}`}
      style={{
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Statistic
        title={title}
        value={value}
        formatter={formatter}
        suffix={suffix}
        valueStyle={{ fontSize: "24px" }}
        prefix={icon}
      />
    </Card>
  );
};

export default StatCard;
