import React, { useEffect, useState } from "react";
import { Courier, CourierStatus, CourierType } from "../../types/couriers.type";
import axios from "axios";
import { API } from "../../config/config";
import {
  Input,
  Card,
  Row,
  Col,
  Select,
  Typography,
  Spin,
  Divider,
  Pagination,
} from "antd";
import {
  UserOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

export default function Couriers() {
  const [couriers, setCouriers] = useState<Courier[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<CourierStatus | "">("");
  const [typeFilter, setTypeFilter] = useState<CourierType | "">("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);

  const fetchCouriers = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API}/admin/couriers`, {
        params: {
          query,
          city: searchCity,
          status: statusFilter,
          type: typeFilter,
          page,
          limit: pageSize,
        },
      });
      setCouriers(response.data.couriers);
      setTotal(response.data.totalCouriers);
    } catch (error) {
      console.error("Error fetching couriers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCouriers(currentPage);
  }, [query, searchCity, statusFilter, typeFilter, currentPage, pageSize]);

  const handleSearch = (value: string) => {
    setQuery(value);
  };

  const handleCitySearch = (value: string) => {
    setSearchCity(value);
  };

  const handleStatusChange = (value: CourierStatus | "") => {
    setStatusFilter(value);
  };

  const handleTypeChange = (value: CourierType | "") => {
    setTypeFilter(value);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  return (
    <div style={{ padding: "24px" }}>
      <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
        Управление Курьерами
      </Title>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={8}>
          <Search
            placeholder="Поиск по имени или телефону"
            onSearch={handleSearch}
            allowClear
            enterButton
          />
        </Col>
        <Col span={8}>
          <Search
            placeholder="Поиск по городу"
            onSearch={handleCitySearch}
            allowClear
            enterButton
          />
        </Col>
        <Col span={4}>
          <Select
            placeholder="Статусу"
            style={{ width: "100%" }}
            allowClear
            onChange={handleStatusChange}
          >
            <Option value={CourierStatus.ACTIVE}>Активен</Option>
            <Option value={CourierStatus.INACTIVE}>Неактивен</Option>
          </Select>
        </Col>
        <Col span={4}>
          <Select
            placeholder="Типу"
            style={{ width: "100%" }}
            allowClear
            onChange={handleTypeChange}
          >
            <Option value={CourierType.PEDESTRIAN}>Пешеход</Option>
            <Option value={CourierType.CYCLE_MOTO}>Велосипед/Мото</Option>
            <Option value={CourierType.AUTO}>Авто</Option>
            <Option value={CourierType.TRUCK}>Грузовик</Option>
          </Select>
        </Col>
      </Row>

      <Divider />

      {loading ? (
        <Spin size="large" style={{ display: "block", margin: "auto" }} />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            {couriers.length === 0 ? (
              <Col span={24} style={{ textAlign: "center" }}>
                <Text type="secondary">Курьеры не найдены</Text>
              </Col>
            ) : (
              couriers.map((courier) => (
                <Col key={courier.phone} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    hoverable
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <UserOutlined style={{ marginRight: 8 }} />
                        {courier.name} {courier.surname}
                      </div>
                    }
                    cover={
                      <img
                        alt="Фото курьера"
                        src={`${API}/${courier.photoUri}`}
                        style={{
                          objectFit: "cover",
                          height: "200px",
                        }}
                      />
                    }
                    bordered
                  >
                    <p>
                      <PhoneOutlined /> <strong>Телефон:</strong>{" "}
                      {courier.phone}
                    </p>
                    <p>
                      <strong>Тип:</strong> {courier.type}
                    </p>
                    <p>
                      <strong>Город:</strong> {courier.city}
                    </p>
                    <p>
                      <strong>Баланс:</strong> {courier.balance.amount} ₽
                    </p>
                    <p>
                      <strong>Статус:</strong> {courier.status}
                    </p>
                  </Card>
                </Col>
              ))
            )}
          </Row>

          <Divider />

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={total}
            showSizeChanger
            onChange={handlePageChange}
            style={{ textAlign: "center", marginTop: "24px" }}
          />
        </>
      )}
    </div>
  );
}
