import { Point } from "./order.type";

export enum SellerStatus {
    ACTIVE = "активен",
    INACTIVE = "неактивен",
}
export enum DaysOfWeek {
    MONDAY = "Пн",
    TUESDAY = "Вт",
    WEDNESDAY = "Ср",
    THURSDAY = "Чт",
    FRIDAY = "Пт",
    SATURDAY = "Сб",
    SUNDAY = "Вс",
}

export interface IDaySchedule {
    dayOfWeek: DaysOfWeek;
    openTime: string;
    closeTime: string;
    isDayOff: boolean;
    is24Hours: boolean;
}

export interface ISchedule extends Array<IDaySchedule> { }

export interface Seller {
    _id: string;
    name: string;
    email?: string;
    address: Point;
    photoUri?: string;
    banner?: string;
    status: SellerStatus;
    phone: string;
    schedule?: ISchedule;
    category: string;
    isBranch: boolean;
    sellerBranch?: string;
}
