import axios from "axios";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { API } from "../config/config";

interface AuthState {
  token: string | null;
  authenticated: boolean | null;
  username: string | null;
}
interface AuthContext {
  authState: AuthState;
  onLogin: (username: string, password: string) => Promise<any>;
}
const defaultAuthContext: AuthContext = {
  authState: {
    token: null,
    authenticated: null,
    username: null,
  },
  onLogin: async () => Promise.resolve(),
};
export const useAuth = () => useContext(AuthContext);

const AuthContext = createContext(defaultAuthContext);
export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({
    token: null,
    authenticated: null,
    username: null,
  });
  useEffect(() => {
    const token = localStorage.getItem("token");
    const authStateString = localStorage.getItem("authState");

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let localState = null;

      if (authStateString) {
        localState = JSON.parse(authStateString);
        setAuthState(localState);
      }
    } else {
      console.log("No token found in localStorage.");
    }
  }, []);

  const login = async (username: string, password: string) => {
    try {
      const response = await axios.post(`${API}/admin/login`, {
        username,
        password,
      });
      const token = response.data.token;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);

      setAuthState({
        token,
        authenticated: true,
        username,
      });

      localStorage.setItem(
        "authState",
        JSON.stringify({ token, authenticated: true, username })
      );

      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      return (error as any).response;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        onLogin: login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
