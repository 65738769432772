import React from "react";
import "@fontsource/fira-mono";
import "@fontsource/fira-mono/400.css";
import "@fontsource/fira-mono/500.css";
import Login from "./pages/Login/login.page";
import { Route, Routes } from "react-router-dom";
import Root from "./components/Root/root.componen";
import Dashboard from "./pages/Dashboard/dashboard.page";
import PrivateRoutes from "./utils/PrivateRoutes";
import Orders from "./pages/Orders/orders.page";
import Sellers from "./pages/Sellers/sellers.page";
import Clients from "./pages/Clients/clients.page";
import Couriers from "./pages/Couriers/couriers.page";
import Pricings from "./pages/Pricings/pricings.page";

function App() {
  const token = localStorage.getItem("token");

  return (
    <div className="App">
      <Routes>
        {!token && <Route path="login" element={<Login />} />}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Root />}>
            <Route index element={<Dashboard />} />
            <Route path="orders" element={<Orders />} />
            <Route path="sellers" element={<Sellers />} />
            <Route path="clients" element={<Clients />} />
            <Route path="couriers" element={<Couriers />} />
            <Route path="pricings" element={<Pricings />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
