import React, { useEffect, useState } from "react";
import { API } from "../../config/config";
import { Order, OrderProduct, OrderStatus } from "../../types/order.type";
import axios from "axios";
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table";
import {
  Button,
  Tag,
  Drawer,
  Typography,
  Divider,
  List,
  Row,
  Col,
  Statistic,
  Input,
  Space,
  DatePicker,
  Select,
  Tooltip,
  Card,
} from "antd";
import { getTagColor } from "../../utils/getTagColor";
import dayjs from "dayjs";

const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

export default function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    trackingId: "",
    startDate: null,
    endDate: null,
    city: "",
    status: "",
    priceFrom: undefined,
    priceTo: undefined,
  });

  const fetchOrders = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API}/admin/orders`, {
        params: {
          page: pagination.current,
          limit: pagination.pageSize,
          ...filters,
          ...params,
        },
      });

      setOrders(response.data.orders);

      setPagination((prev) => ({
        ...prev,
        total: response.data.totalOrders,
        current: response.data.page,
        pageSize: response.data.limit,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOrders();
  }, [pagination.current, pagination.pageSize, filters]);

  const getTotalPrice = (products: OrderProduct[]) =>
    products.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    );

  const getTotalWeight = (products: OrderProduct[]) =>
    products.reduce(
      (sum, product) => sum + product.weight * product.quantity,
      0
    );

  const showDrawer = (order: Order) => {
    setCurrentOrder(order);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setCurrentOrder(null);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const handleSearch = (value: string) => {
    setFilters({ ...filters, trackingId: value });
    fetchOrders();
  };

  const handleCitySearch = (value: string) => {
    setFilters({ ...filters, city: value });
    fetchOrders();
  };

  const handleDateRangeChange = (dates: any) => {
    if (dates) {
      setFilters({
        ...filters,
        startDate: dates[0].startOf("day").toISOString(),
        endDate: dates[1].endOf("day").toISOString(),
      });
    } else {
      setFilters({ ...filters, startDate: null, endDate: null });
    }
  };

  const columns: ColumnsType<Order> = [
    {
      title: "ID отслеживания",
      dataIndex: "trackingId",
      key: "trackingId",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Дата",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      width: 200,
      render: (text: Date) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Адрес клиента",
      key: "clientPoint",
      width: 250,
      render: (text: any, record: Order) => (
        <div
          style={{
            maxWidth: 250,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {record.clientPoint.city} , {record.clientPoint.street} , дом{" "}
          {record.clientPoint.house} , вход {record.clientPoint.entrance} кв{" "}
          {record.clientPoint.apartment} , {record.clientPoint.floor} этаж
        </div>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status: OrderStatus) => (
        <Tag color={getTagColor(status)}>{status}</Tag>
      ),
    },
    {
      title: "Цена товаров",
      key: "totalPrice",
      width: 130,
      render: (text: any, record: Order) => <span>{record.price}₽</span>,
    },
    {
      title: "Цена доставки (клиента)",
      key: "deliveryAmount",
      width: 150,
      render: (text: any, record: Order) => (
        <span>{record.deliveryAmount}₽</span>
      ),
    },
    {
      title: "Цена доставки (в общем)",
      key: "totalDeliveryAmount",
      width: 150,
      render: (text: any, record: Order) => (
        <span>{record.totalDeliveryAmount}₽</span>
      ),
    },
    {
      title: "Сумма",
      key: "total",
      width: 130,
      render: (text: any, record: Order) => <span>{record.total}₽</span>,
    },
    {
      title: "Общий вес",
      key: "totalWeight",
      width: 150,
      render: (text: any, record: Order) => (
        <span>
          {record.weight / 1000}
          кг
        </span>
      ),
    },
    {
      title: "Действия",
      key: "actions",
      width: 150,
      render: (text: any, record: Order) => (
        <Button type="primary" onClick={() => showDrawer(record)}>
          Подробнее
        </Button>
      ),
      fixed: "right",
    },
  ];

  return (
    <>
      <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
        Заказы
      </Title>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Поиск по ID отслеживания"
          onSearch={handleSearch}
          style={{ width: 200 }}
          allowClear
          onClear={() => handleSearch("")}
        />
        <Search
          placeholder="Поиск по городу"
          onSearch={handleCitySearch}
          style={{ width: 200 }}
          allowClear
          onClear={() => handleCitySearch("")}
        />
        <RangePicker onChange={handleDateRangeChange} />
        <Select
          placeholder="Статус"
          onChange={(value) => setFilters({ ...filters, status: value })}
          style={{ width: 150 }}
          allowClear
        >
          {Object.values(OrderStatus).map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Space>
      <Divider />

      <Table
        dataSource={orders}
        columns={columns}
        loading={loading}
        rowKey="trackingId"
        pagination={{
          ...pagination,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
      />
      <Drawer
        width={720}
        onClose={onClose}
        open={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {currentOrder && (
          <>
            <Title level={4} style={{ color: "#1890ff" }}>
              Детали заказа
            </Title>
            <Paragraph>
              <strong>ID отслеживания:</strong>{" "}
              <Tooltip title="Уникальный идентификатор заказа">
                {currentOrder.trackingId}
              </Tooltip>
            </Paragraph>
            <Paragraph>
              <strong>Дата доставки:</strong>{" "}
              {dayjs(currentOrder.deliveryDate).format("YYYY-MM-DD HH:mm:ss")}
            </Paragraph>
            <Paragraph>
              <strong>Имя клиента:</strong> {currentOrder.clientName}
            </Paragraph>
            <Paragraph>
              <strong>Адрес клиента:</strong> {currentOrder.clientPoint.city} ,{" "}
              {currentOrder.clientPoint.street} , дом{" "}
              {currentOrder.clientPoint.house} , вход{" "}
              {currentOrder.clientPoint.entrance} кв{" "}
              {currentOrder.clientPoint.apartment} ,{" "}
              {currentOrder.clientPoint.floor} этаж
            </Paragraph>
            <Paragraph>
              <strong>Телефон клиента:</strong> {currentOrder.clientPhone}
            </Paragraph>
            <Paragraph>
              <strong>Имя курьера:</strong>{" "}
              {currentOrder.courierId || <Tag color="red">Нет данных</Tag>}
            </Paragraph>
            <Paragraph>
              <strong>Телефон курьера:</strong>{" "}
              {currentOrder.courierPhone || <Tag color="red">Нет данных</Tag>}
            </Paragraph>
            <Divider />

            <Title level={4} style={{ color: "#1890ff" }}>
              Продавцы
            </Title>
            {currentOrder.sellerProducts.map((sellerProduct, index) => (
              <Card key={index} style={{ marginBottom: 16 }}>
                <Paragraph>
                  <strong>Продавец:</strong> {sellerProduct.name}
                </Paragraph>
                <Paragraph>
                  <strong>Адрес продавца:</strong>{" "}
                  {currentOrder.points[index]?.city},{" "}
                  {currentOrder.points[index]?.street}, дом{" "}
                  {currentOrder.points[index]?.house}, вход{" "}
                  {currentOrder.points[index]?.entrance}, кв.{" "}
                  {currentOrder.points[index]?.apartment}, этаж{" "}
                  {currentOrder.points[index]?.floor}
                </Paragraph>
                <Paragraph>
                  <strong>Сумма товаров:</strong>{" "}
                  {getTotalPrice(sellerProduct.products).toFixed(2)} ₽
                </Paragraph>
                <Paragraph>
                  <strong>Вес:</strong>{" "}
                  {(getTotalWeight(sellerProduct.products) / 1000).toFixed(2)}{" "}
                  кг
                </Paragraph>
                <Paragraph>
                  <strong>Заплатил за доставку:</strong>{" "}
                  {sellerProduct.deliveryAmount} ₽
                </Paragraph>
                <List
                  itemLayout="horizontal"
                  dataSource={sellerProduct.products}
                  renderItem={(product) => (
                    <List.Item>
                      <List.Item.Meta
                        title={product.title}
                        description={`Опция: ${product.selectedOption} | Цена: ${product.price} ₽ | Количество: ${product.quantity}`}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            ))}
            <Divider />
            <Divider />
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="Общая цена"
                  value={currentOrder.total.toFixed(2)}
                  suffix="₽"
                />
                <Statistic
                  title="Цена товаров"
                  value={currentOrder.price.toFixed(2)}
                  suffix="₽"
                />
                <Statistic
                  title="Цена за доставку (клиента)"
                  value={currentOrder.deliveryAmount.toFixed(2)}
                  suffix="₽"
                />
                <Statistic
                  title="Цена за доставку (в общем)"
                  value={currentOrder.totalDeliveryAmount.toFixed(2)}
                  suffix="₽"
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Общий вес"
                  value={(currentOrder.weight / 1000).toFixed(2)}
                  suffix="кг"
                />
              </Col>
            </Row>
          </>
        )}
      </Drawer>
    </>
  );
}
