import React, { useEffect, useState } from "react";
import { Client } from "../../types/client.type";
import axios from "axios";
import { API } from "../../config/config";
import {
  Input,
  Card,
  Row,
  Col,
  Divider,
  Typography,
  Spin,
  Pagination,
} from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const { Title, Text } = Typography;

export default function Clients() {
  const [clients, setClients] = useState<Client[]>([]);
  const [query, setQuery] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalClients, setTotalClients] = useState<number>(0);

  const fetchClients = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API}/admin/clients`, {
        params: {
          query,
          city: searchCity,
          page: currentPage,
          limit: pageSize,
        },
      });
      setClients(response.data.clients);
      setTotalClients(response.data.totalClients);
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [query, searchCity, currentPage, pageSize]);

  const handleSearch = (value: string) => {
    setQuery(value);
    setCurrentPage(1);
  };

  const handleCitySearch = (value: string) => {
    setSearchCity(value);
    setCurrentPage(1);
  };

  return (
    <div style={{ padding: "24px" }}>
      <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
        Управление Клиентами
      </Title>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={12}>
          <Search
            placeholder="Поиск по имени или телефону"
            onSearch={handleSearch}
            allowClear
            enterButton
          />
        </Col>
        <Col span={12}>
          <Search
            placeholder="Поиск по городу"
            onSearch={handleCitySearch}
            allowClear
            enterButton
          />
        </Col>
      </Row>
      <Divider />

      {loading ? (
        <Spin size="large" style={{ display: "block", margin: "auto" }} />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            {clients.length === 0 ? (
              <Col span={24} style={{ textAlign: "center" }}>
                <Text type="secondary">Клиенты не найдены</Text>
              </Col>
            ) : (
              clients.map((client) => (
                <Col key={client.phone} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    hoverable
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <UserOutlined style={{ marginRight: 8 }} />{" "}
                        {client.name} {client.surname}
                      </div>
                    }
                    cover={
                      <img
                        alt="Фото клиента"
                        src={`${API}/${client.photoUri}`}
                        style={{
                          objectFit: "cover",
                          height: "200px",
                        }}
                      />
                    }
                    bordered
                  >
                    <p>
                      <PhoneOutlined /> <strong>Телефон:</strong> {client.phone}
                    </p>
                    <p>
                      <MailOutlined /> <strong>Email:</strong>{" "}
                      {client.email || "Не указана"}
                    </p>
                    <p>
                      <CalendarOutlined /> <strong>Дата рождения:</strong>
                      {client.dateOfBirth || "Не указана"}
                    </p>
                    <p>
                      <strong>Гендер:</strong> {client.gender || "Не указан"}
                    </p>
                    <Divider />
                    <strong>Адреса:</strong>
                    {client.address.map((addr, index) => (
                      <div key={addr._id}>
                        <Text>{`${index + 1}.${addr.city}, ${
                          addr.street
                        }, дом ${addr.house}, вход ${addr.entrance} кв ${
                          addr.apartment
                        }, ${addr.floor} этаж`}</Text>
                      </div>
                    ))}
                  </Card>
                </Col>
              ))
            )}
          </Row>

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalClients}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            }}
            style={{ marginTop: "20px", textAlign: "center" }}
            showSizeChanger
            pageSizeOptions={[5, 10, 20, 50]}
          />
        </>
      )}
    </div>
  );
}
