import { useState, useEffect } from "react";
import { Layout, Typography, Switch } from "antd";
import { BulbOutlined, BulbFilled } from "@ant-design/icons";
import Sidebar from "../Sidebar/sidebar.component";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/header.component";

const { Sider, Content } = Layout;
const { Title } = Typography;

export default function Root() {
  const getStoredTheme = () => localStorage.getItem("darkTheme") === "true";
  const getStoredCollapsed = () => localStorage.getItem("collapsed") === "true";

  const [collapsed, setCollapsed] = useState(getStoredCollapsed());
  const [darkTheme, setDarkTheme] = useState(getStoredTheme());
  const location = useLocation();
  useEffect(() => {
    localStorage.setItem("darkTheme", darkTheme.toString());
  }, [darkTheme]);

  useEffect(() => {
    localStorage.setItem("collapsed", collapsed.toString());
  }, [collapsed]);

  const toggleTheme = (checked: boolean) => {
    setDarkTheme(checked);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme={darkTheme ? "dark" : "light"}
        width={240}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          left: 0,
          top: 0,
          bottom: 0,
          background: darkTheme ? "#001529" : "#fff",
          color: darkTheme ? "#fff" : "#000",
        }}
      >
        <Sidebar darkTheme={darkTheme} />
        <Switch
          checkedChildren={<BulbFilled style={{ color: "#ffd700" }} />}
          unCheckedChildren={<BulbOutlined />}
          checked={darkTheme}
          onChange={toggleTheme}
          style={{
            margin: "16px",
            display: "block",
            textAlign: "center",
          }}
        />
      </Sider>
      <Layout style={{ padding: 24, minHeight: "100vh" }}>
        <Header darkTheme={darkTheme} />
        <Content style={{ paddingTop: 80 }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
