export type Point = {
    city: string;
    country: string;
    street: string;
    latitude: number;
    longitude: number;
    entrance: string;
    floor: number;
    apartment: string;
    house: string;
};

export enum OrderStatus {
    AwaitingPayment = "Ожидание оплаты",
    AwaitingCourier = "Ожидание курьера",
    CourierPickedUp = "Курьер забрал",
    InTransit = "В пути",
    Delivered = "Доставлено",
    Cancelled = "Отменено",
    UnderReview = "На рассмотрении",
}

export interface Order {
    clientId: string;
    deliveryAmount: number;
    totalDeliveryAmount: number;
    price: number;
    total: number;
    points: Point[];
    weight: number;
    distance: number;
    clientPhone: string;
    clientPoint: Point;
    clientName: string;
    comment?: string;
    courierId: string;
    courierPhone?: string;
    status: OrderStatus;
    trackingId: string;
    sellerProducts: OrderSellerProduct[];
    createdAt: Date;
    deliveryDate: Date;
}

export interface OrderSellerProduct {
    seller: string;
    name: string;
    photoUri: string;
    deliveryAmount: number;
    products: OrderProduct[];
}

export interface OrderProduct {
    _id: string;
    title: string;
    photoUri: string;
    selectedOption: string;
    selectedOptionIndex: Number;
    weight: number;
    owner: string;
    ownerName: string;
    ownerPhoto: string;
    quantity: number;
    price: number;
}
